import { Alert, Backdrop, Breadcrumbs, Button, CircularProgress, FormControl, InputLabel, Link, Typography, TextField, Select, MenuItem, InputAdornment, AlertTitle } from '@mui/material'
import { useEffect, useState } from 'react'
import ToppMeny from '../komponenter/Toppmeny'
import { Text } from '../komponenter/Tekst'
import HomeIcon from '@mui/icons-material/Home';
import FolderIcon from '@mui/icons-material/Folder';
import SearchIcon from '@mui/icons-material/Search';
import axios from 'axios';
import Paper from '@mui/material/Paper';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import Grid from '@mui/material/Grid2';


const columns: GridColDef[] = [
    { field: 'MeldingId', headerName: 'Referanse', type: 'number', width: 150 },
    {
        field: 'OpprettetDato', headerName: 'Dato', width: 130, valueFormatter: (params: any) => {
            const dato = new Date(params);
            return dato.toLocaleDateString('nb-NO', { day: '2-digit', month: '2-digit', year: 'numeric' });
        }
    },
    { field: 'Virksomhet', headerName: 'Virksomhet', width: 300 },
    { field: 'Kontaktperson', headerName: 'Kontaktperson', width: 300 },
    { field: 'Beskrivelse', headerName: 'Beskrivelse', flex: 1 },

];

const paginationModel = { page: 0, pageSize: 10 };

const APIURL = process.env.REACT_APP_APIURL;

const aktivAktorNavn = sessionStorage.getItem('jvaktornavn');

function Arkiv() {
    const [isLoading, setisLoading] = useState(true);
    const [meldinger, setMeldinger] = useState<any[]>([]);
    // const [valgtInstrument, setValgtInstrument] = useState("0");
    const [valgtMeldingstype, setValgtMeldingstype] = useState("0");
    // const [eiersok, setEiersok] = useState('');
    const [fraDato, setFraDato] = useState('');
    const [tilDato, setTilDato] = useState('');
    const [fritekst, setFritekst] = useState('');

    const jvsesaktorid = sessionStorage.getItem('jvaktorid') || "";
    const [jvbrukerverifisert, setjvbrukerverifisert] = useState(false);
    const [jvmobilnummerverifisert, setjvmobilnummerverifisert] = useState(false);
    const [jvaktorverifisert, setjvaktorverifisert] = useState(false);

    const token = sessionStorage.getItem("jvtoken") || "";

    const fetchMeldinger = async () => {
        setisLoading(true);
        var formdata = {
            // aktorid: eiersok,
            sok: fritekst,
            fraDato: fraDato,
            tilDato: tilDato,
            // instrument: valgtInstrument,
            meldingtype: valgtMeldingstype
        };
        axios.post(APIURL + "/arkiv", formdata, { withCredentials: true, headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' } })
            .then((response: any) => {
                if (response.status === 200) {

                    const meldinger = response.data.map((melding: any) => ({
                        ...melding,
                        id: melding.MeldingId // Setter id til MeldingId for å sikre unik id for hver rad
                    }));
                    setMeldinger(meldinger);
                    console.log(response.data);
                }
                else {
                }
                setisLoading(false);
            })
            .catch((e: any) => {
                setisLoading(false);
            });
    }

    useEffect(() => {
        document.title = 'Din Side - Justervesenet';
        if ((jvsesaktorid === "undefined") || (jvsesaktorid === null)) {
            window.location.assign("/profil");
        }
        const jvbrukerverifisert = sessionStorage.getItem('jvbrukerverifisert');
        const isVerified = jvbrukerverifisert === 'true';
        setjvbrukerverifisert(isVerified);
        const jvmobilnummerverifisert = sessionStorage.getItem('jvmobilnummerverifisert');
        const isVerifiedMobil = jvmobilnummerverifisert === 'true';
        setjvmobilnummerverifisert(isVerifiedMobil);
        const jvaktorverifisert = sessionStorage.getItem('jvaktorverifisert');
        const isVerifiedAktor = jvaktorverifisert === 'true';
        setjvaktorverifisert(isVerifiedAktor);
        fetchMeldinger();
        setisLoading(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, []);

    useEffect(() => {
        fetchMeldinger();
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, [valgtMeldingstype]);

    return (
        <main>
            {
                isLoading ? (
                    <Backdrop
                        open={isLoading}
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1000 }}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop >
                ) : (
                    (jvbrukerverifisert && jvmobilnummerverifisert && jvaktorverifisert) ? (
                        <div>
                            <ToppMeny />
                            <Breadcrumbs aria-label="breadcrumb" sx={{ padding: 1 }}>
                                <Link
                                    underline="hover"
                                    sx={{ display: 'flex', alignItems: 'center' }}
                                    color="inherit"
                                    href="/forsiden"
                                >
                                    <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                                    <Text tid="forsiden" />
                                </Link>
                                <Typography
                                    sx={{ display: 'flex', alignItems: 'center' }}
                                    color="text.primary"
                                >
                                    Arkiv
                                </Typography>
                            </Breadcrumbs>

                            <Grid container spacing={2} p={2}>
                                <Grid size={12}>
                                    <Typography variant="h6" component="div">
                                        <Typography variant="h6" color="text.secondary" gutterBottom className='jvcardtitle'>
                                            <FolderIcon /> &nbsp;Arkiv for {aktivAktorNavn}
                                        </Typography>
                                    </Typography>
                                    <Alert severity="info">
                                        <AlertTitle>Informasjon</AlertTitle>
                                        Arkivfunksjonen er under utvikling, detaljer om innsendte meldinger blir snart tilgjengelig.
                                    </Alert>
                                </Grid>
                                {/* <Grid size={12}>
                                <Box sx={{ maxWidth: 400 }}>
                                    <FormControl fullWidth>
                                        <InputLabel id="typeInstrumentLabel">Type instrument</InputLabel>
                                        <Select
                                            labelId="typeInstrumentLabel"
                                            id="typeInstrument"
                                            value={valgtInstrument}
                                            label="typeInstrument"
                                            onChange={(e) => setValgtInstrument(e.target.value)}
                                        >
                                            <MenuItem value="0">Alle</MenuItem>
                                            <MenuItem value="310">Taksameter</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Box>
                            </Grid> */}

                                <Grid size={4}>
                                    <FormControl fullWidth>
                                        <InputLabel id="meldingstypeLabel">Meldingstype</InputLabel>
                                        <Select
                                            labelId="meldingstypeLabel"
                                            id="meldingstype"
                                            value={valgtMeldingstype}
                                            label="meldingstype"
                                            onChange={(e) => setValgtMeldingstype(e.target.value)}
                                        >
                                            <MenuItem value="0">
                                                Alle
                                            </MenuItem>
                                            <MenuItem value="10">
                                                Melding om installasjonskontroll (taxi)
                                            </MenuItem>
                                            <MenuItem value="8">
                                                Utført reparasjon/plombebrudd uten pålegg fra Justervesenet
                                            </MenuItem>
                                            <MenuItem value="9">
                                                Utført retting/plombebrudd etter pålegg fra Justervesenet
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>

                                {/* <Grid size={4}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    label="Eier av instrument"
                                    value={eiersok}
                                    onChange={(e) => setEiersok(e.target.value)}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <SearchIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid> */}

                                {/* Filtrering fra og til dato */}
                                <Grid size={3}>
                                    <TextField
                                        fullWidth
                                        label="Fra dato"
                                        type="date"
                                        slotProps={{ inputLabel: { shrink: true } }}
                                        value={fraDato}
                                        onChange={(e) => setFraDato(e.target.value)}
                                    />
                                </Grid>

                                <Grid size={3}>
                                    <TextField
                                        fullWidth
                                        label="Til dato"
                                        type="date"
                                        slotProps={{ inputLabel: { shrink: true } }}
                                        value={tilDato}
                                        onChange={(e) => setTilDato(e.target.value)}
                                    />
                                </Grid>
                                <Grid size={12}>
                                    <TextField
                                        fullWidth
                                        variant="outlined"
                                        label="Fritekstsøk"
                                        value={fritekst}
                                        onChange={(e) => setFritekst(e.target.value)}
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') {
                                                e.preventDefault();
                                                fetchMeldinger();
                                            }
                                        }}
                                        slotProps={{
                                            input: {
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <SearchIcon />
                                                    </InputAdornment>
                                                ),
                                            },
                                        }}
                                    />
                                </Grid>
                                <Grid size={12}>
                                    <Button
                                        size="small"
                                        type="submit"
                                        variant="outlined"
                                        color="success"
                                        onClick={fetchMeldinger}
                                    >
                                        <SearchIcon className='jvbtnicon' />
                                        Søk
                                    </Button>
                                </Grid>
                                <Grid size={12}>
                                    <Typography variant="h6" component="h2" className='jvcardtitle'>
                                        <SearchIcon className='jvcardtitleicon' /> Søketreff {meldinger.length > 0 && <span> &nbsp; ({meldinger.length} treff)</span>}
                                    </Typography>
                                    <Paper sx={{ width: '100%' }}>
                                        <DataGrid
                                            rows={meldinger}
                                            columns={columns}
                                            initialState={{ pagination: { paginationModel } }}
                                            pageSizeOptions={[10, 25, 50, 100]}
                                            sx={{ border: 0 }}
                                        />
                                    </Paper>
                                </Grid>
                            </Grid>
                        </div>
                    ) : (
                        <>
                            <ToppMeny />
                            <Breadcrumbs aria-label="breadcrumb" sx={{ padding: 1 }}>
                                <Link
                                    underline="hover"
                                    sx={{ display: 'flex', alignItems: 'center' }}
                                    color="inherit"
                                    href="/forsiden"
                                >
                                    <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                                    <Text tid="forsiden" />
                                </Link>
                                <Typography
                                    sx={{ display: 'flex', alignItems: 'center' }}
                                    color="text.primary"
                                >
                                    Arkiv
                                </Typography>
                            </Breadcrumbs>

                            <Grid container spacing={2} p={2}>
                                <Grid size={12}>
                                    {(!jvbrukerverifisert) &&
                                        <Alert severity="warning">
                                            <AlertTitle>Din e-post er ikke verifisert.</AlertTitle>
                                            For å få tilgang til arkivet må du først verifisere din e-post. Gå til <Link href="/profil">min profil</Link> for å gjøre dette.
                                        </Alert>
                                    }
                                    {(!jvmobilnummerverifisert) &&
                                        <Alert severity="warning">
                                            <AlertTitle>Ditt mobilnummer er ikke verifisert.</AlertTitle>
                                            For å få tilgang til arkivet må du først verifisere ditt mobilnummer. Gå til <Link href="/profil">min profil</Link> for å gjøre dette.
                                        </Alert>
                                    }
                                    {(!jvaktorverifisert) &&
                                        <Alert severity="warning">
                                            <AlertTitle>Du er ikke verifisert tilknyttet din virksomhet.</AlertTitle>
                                            For å få tilgang til arkivet må du først bli verifisert tilknyttet din virksomhet. Gå til <Link href="/profil">min profil</Link> for å gjøre dette.
                                        </Alert>
                                    }
                                </Grid>
                            </Grid>
                        </>
                    )
                )
            }
        </main>
    );
}

export default Arkiv;