import { Alert, Backdrop, Breadcrumbs, Card, CardContent, CircularProgress, Link, Tooltip, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import ToppMeny from '../komponenter/Toppmeny'
import { Text } from '../komponenter/Tekst'
import axios from 'axios';
import HomeIcon from '@mui/icons-material/Home';
import VerifiedIcon from '@mui/icons-material/Verified';
import ListAltIcon from '@mui/icons-material/ListAlt';
import Grid from '@mui/material/Grid2';

const APIURL = process.env.REACT_APP_APIURL;

function Aarsavgift() {
    const [isLoading, setisLoading] = useState(true);
    const [mineAktorer, setMineAktorer] = useState<any[]>([]);
    const token = sessionStorage.getItem("jvtoken") || "";

    function hentMineAktorer() {
        axios.get(APIURL + "/aarsavgift/bensin/aktorer", { withCredentials: true, headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' } })
            .then((response: any) => {
                if (response.status === 200) {
                    setMineAktorer(response.data);
                }
            })
            .catch((e: any) => {
            });
    }

    useEffect(() => {
        document.title = 'Årsavgift - Justervesenet';
        hentMineAktorer();
        setisLoading(false);
        // eslint-disable-next-line
    }, []);

    return (
        <main>
            {
                isLoading ?
                    <div>
                        <Backdrop
                            open={isLoading}
                            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        >
                            <CircularProgress color="inherit" />
                        </Backdrop >
                    </div>
                    :
                    <div>
                        <ToppMeny />
                        <Breadcrumbs aria-label="breadcrumb" sx={{ padding: 1 }}>
                            <Link
                                underline="hover"
                                sx={{ display: 'flex', alignItems: 'center' }}
                                color="inherit"
                                href="/forsiden"
                            >
                                <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                                <Text tid="forsiden" />
                            </Link>
                            <Typography
                                sx={{ display: 'flex', alignItems: 'center' }}
                                color="text.primary"
                            >
                                <Text tid="aarsavgift" />
                            </Typography>
                        </Breadcrumbs>

                        <Grid container spacing={2} p={2}>
                            <Grid size={12}>
                                <Alert severity="info" sx={{ mb: 2 }}>
                                    {Text({ tid: "aarsavgiftmangleraktor" })}
                                </Alert>

                                {mineAktorer.map(({ AktorId, Navn, OrgNummer, Rolle, Verifisert, Admin }: any, index: number) => {
                                    return <span key={AktorId}>
                                        <Link href={"/aarsavgift/bensin/" + OrgNummer} underline="none">
                                            <Card variant="outlined" sx={{ mb: 2 }}>
                                                {!Verifisert &&
                                                    <Alert severity="warning">
                                                        <Text tid="ikkeveraktor" />
                                                    </Alert>
                                                }
                                                <CardContent>
                                                    <Grid sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                        <Typography variant="h6" color="text.secondary" gutterBottom className='jvtypeicon'>
                                                            {Navn} {(Verifisert === true) && <Tooltip title={Text({ tid: "ttaktorver" })}><VerifiedIcon className='jviconverified' sx={{ ml: 1 }} /></Tooltip>}
                                                        </Typography>
                                                    </Grid>
                                                    <Typography variant="body2" color="text.secondary" className='jvtypeicon' sx={{ ml: 3 }}>
                                                        <ListAltIcon className='jvtexticonnc' /> {OrgNummer}
                                                    </Typography>
                                                </CardContent>
                                            </Card>
                                        </Link>
                                    </span>
                                })
                                }

                            </Grid>
                        </Grid>
                    </div>
            }
        </main >
    )
}
export default Aarsavgift;