import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { Text } from './Tekst';
import Grid from '@mui/material/Grid2';

export default function Footer() {
    return (
        <Box
            component="footer"
            sx={{
                py: 1,
                px: 2,
                mt: 'auto',
                width: "100%",
                position: "fixed",
                bottom: 0,
                backgroundColor: (theme) =>
                    theme.palette.mode === 'light'
                        ? theme.palette.grey[200]
                        : theme.palette.grey[900],
            }}
        >
            <Grid container>
                <Grid size={{ xs: 12, md: 4 }} pb={2}>
                    <Typography variant="body2" sx={{ fontSize: 12 }}>
                        din side © {new Date().getFullYear()} <Link href='https://www.justervesenet.no/'>Justervesenet</Link><br />
                        <Link href='https://www.justervesenet.no/personvernerklaering/'><Text tid="personvern" /></Link><br />
                        <Link href='#'><Text tid="informasjonskapsler" /></Link>
                    </Typography>
                </Grid>
                <Grid size={{ xs: 12, md: 4 }} pb={2}>
                    <Typography variant="body2" sx={{ fontSize: 12 }}>
                        Justervesenet<br />
                        Postboks 170<br />
                        NO-2027 Kjeller, Norway
                    </Typography>
                </Grid>
                <Grid size={{ xs: 12, md: 4 }}>
                    <Typography variant="body2" sx={{ fontSize: 12 }}>
                        <Text tid="epost" />: <Link href="mailto:postmottak@justervesenet.no">postmottak@justervesenet.no</Link><br />
                        <Text tid="telefon" />: <Link href="tel:004764848484">+47 64 84 84 84</Link>
                    </Typography>
                </Grid>
            </Grid>
        </Box>
    );
}